/* eslint-disable no-prototype-builtins */
import * as Sentry from '@sentry/browser'
import emitter from '../../util-functions/emitter'

const errorReport = function (data) {
  const userLocalStorageData = {}
  Object.keys(window.localStorage).reduce((obj, str) => {
    userLocalStorageData[str] = window.localStorage.getItem(str)
    return userLocalStorageData
  }, {})

  const dataWithLocalStorage = Object.assign(userLocalStorageData, data)
  const jsonKeys = ['crmUser', 'user', 'crm_instance']
  for (let i = 0; i < jsonKeys.length; i++) {
    if (dataWithLocalStorage.hasOwnProperty(jsonKeys[i])) {
      dataWithLocalStorage[jsonKeys[i]] = JSON.parse(dataWithLocalStorage[jsonKeys[i]])
    }
  }

  emitter.emit('api/error', data)

  if (data.error && data.error.length > 0) {
    const isBusinessLogicError = data.error.find(v => v.category === 'businessLogic')
    const isAuthorizationError = data.error.find(v => v.message === 'Authorization error')
    if (isBusinessLogicError || isAuthorizationError) {
      return
    }
  }

/*  Sentry.withScope(scope => {
    Object.keys(dataWithLocalStorage).forEach(key => {
      let value = dataWithLocalStorage[key]
      scope.setExtra(key, value)
    })
    Sentry.captureException(new Error(getErrorMsgTitle(data)))
  })*/

  const errorMsg = getErrorMsgTitle(data);
  const storageKey = `reportedError_${errorMsg}`;

  const today = new Date().toISOString().slice(0, 10);
  if (window.localStorage.getItem(storageKey) === today) {
    console.log("no need to report already send for today")
  } else {
    window.localStorage.setItem(storageKey, today);
    Sentry.withScope(scope => {
      Object.keys(dataWithLocalStorage).forEach(key => {
        let value = dataWithLocalStorage[key]
        scope.setExtra(key, value)
      })
      console.log("send request to Sentry -> ", storageKey)
      Sentry.captureException(new Error(getErrorMsgTitle(data)))
    })
  }

}

function getErrorMsgTitle(data) {
  let error
  if (data.hasOwnProperty('error')) {
    if (Array.isArray(data.error)) {
      error = Array.isArray(data.error) && data.error.length > 0 ? data.error[0] : undefined
    } else if (typeof data.error === 'object') {
      if (data.error.hasOwnProperty('errors')) {
        if (Array.isArray(data.error.errors) && data.error.errors.length > 0) {
          error = data.error.errors[0]
        }
      }
    }
  }

  if (typeof error === 'object' && error.hasOwnProperty('message')) {
    return error.message
  }

  return 'Graphql Api Error V.3 at time : ' + Date.now()
}

export default errorReport
